<!-- 我的收藏 -->
<template>
  <div class="collect">
    <!-- Add a static page for my favorite module -->
    <div class="collect-header">
      <div class="collect-title">
        <el-row>
          <el-col :span="8">
            <i class="el-icon-collection-tag" style="color: #ff6700"></i>
            我的收藏
          </el-col>
          <el-col :span="12">
            <div>
              <el-input
                placeholder="请输入内容"
                v-model="keyword"
                class="input-with-select"
              >
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="searchData"
                ></el-button>
              </el-input>
            </div>
          </el-col>
          <el-col :span="4">&nbsp;</el-col>
        </el-row>
      </div>
    </div>
    <div class="content">
      <div class="goods-list" v-if="collectList.length > 0">
        <MyList :list="collectList" :isDelete="true"></MyList>
      </div>
      <!-- 收藏列表为空的时候显示的内容 -->
      <div v-else class="collect-empty">
        <div class="empty">
          <h2>您的收藏还是空的！</h2>
          <p>快去购物吧！</p>
        </div>
      </div>
      <!--  收藏列表为空的时候显示的内容END -->
      <!-- 底部分页 -->
      <div style="text-align: center">
        <!-- 分页 -->
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :page-size="20"
          @current-change="currentChange"
        ></el-pagination>
        <!-- 分页END -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Collect",
  data() {
    return {
      collectList: [],
      total: 1,
      page: 1,
      keyword: "",
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    /**
     * 异步获取数据
     */
    async fetchData() {
      const url = this.$apiBaseUrl + "/web/fav/list"
      const { data } = await this.$axios.post(url, {
        uuid: this.$store.getters.getUser.uuid,
        page: this.page,
        keyword: this.keyword,
      });
      this.collectList = data.data.list;
      this.total = data.data.total;
    },
    /**
     * 搜索数据
     */
    searchData() {
      this.page = 1
      this.fetchData()
    },
    /**
     * 分页切换
     * @param {*} page
     */
    currentChange(page) {
      this.page = page;
      this.fetchData();
    },
  },
};
</script>
<style>
.collect {
  background-color: #f5f5f5;
}
.collect .collect-header {
  height: 64px;
  background-color: #fff;
  border-bottom: 2px solid #ff6700;
}
.collect .collect-header .collect-title {
  width: 1225px;
  margin: 0 auto;
  height: 64px;
  line-height: 58px;
  font-size: 28px;
}
.collect .content {
  padding: 20px 0;
  width: 1225px;
  margin: 0 auto;
}
.collect .content .goods-list {
  margin-left: -13.7px;
  overflow: hidden;
}
/* 收藏列表为空的时候显示的内容CSS */
.collect .collect-empty {
  width: 1225px;
  margin: 0 auto;
}
.collect .collect-empty .empty {
  height: 300px;
  padding: 0 0 130px 558px;
  margin: 65px 0 0;
  background: url(../assets/imgs/cart-empty.png) no-repeat 124px 0;
  color: #b0b0b0;
  overflow: hidden;
}
.collect .collect-empty .empty h2 {
  margin: 70px 0 15px;
  font-size: 36px;
}
.collect .collect-empty .empty p {
  margin: 0 0 20px;
  font-size: 20px;
}
/* 收藏列表为空的时候显示的内容CSS END */
</style>
